<template>
  <p>Edit event</p>
</template>

<script>
export default {
  name: 'EventEdit',
  props: ['event']
}
</script>
