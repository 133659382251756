<template>
  <h1>Events for Good!</h1>
  <div class="events">
    <EventCard v-for="event in events" :key="event.id" :event="event" />
    <div class="pagination">
      <router-link
        id="page-prev"
        v-if="page != 1"
        :to="{ name: 'EventList', query: { page: page - 1 } }"
        rel="prev"
        >&#60; Previous</router-link
      >

      <router-link
        id="page-next"
        v-if="hasNextPage"
        :to="{ name: 'EventList', query: { page: page + 1 } }"
        rel="next"
        >Next &#62;</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EventCard from '@/components/EventCard.vue'
// import EventService from '@/services/EventService.js'

export default {
  name: 'EventList',
  props: ['page'],
  components: {
    EventCard
  },
  // data() {
  //   return {
  //     events: null,
  //     totalEvents: 0
  //   }
  // },

  methods: {
    ...mapActions(['fetchEvents'])
  },

  created() {
    this.fetchEvents({
      page: parseInt(this.$route.query.page) || 1
    }).catch(error => {
      this.$router.push({
        name: 'ErrorDisplay',
        params: { error: error }
      })
    })
  },

  computed: {
    events() {
      return this.$store?.state.events
    }
  }

  // beforeRouteEnter(to, from, next) {
  //   try {
  //     let response = this.$store.dispatch('fetchEvents', {
  //       page: parseInt(to.query.page) || 1
  //     })
  //     if (response) {
  //       next(comp => {
  //         comp.events = response.data
  //         comp.totalEvents = response.headers['x-total-count']
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     next({ name: 'NetworkError' })
  //   }
  // }

  // beforeRouteUpdate(to) {
  //   return EventService.getEvents(2, parseInt(to.query.page) || 1)
  //     .then(response => {
  //       this.events = response.data
  //       this.totalEvents = response.headers['x-total-count']
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       return { name: 'NetworkError' }
  //     })
  // },

  // computed: {
  //   hasNextPage() {
  //     let totalPages = Math.ceil(this.totalEvents / 2)
  //     return this.page < totalPages
  //   }
  // }
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination {
  display: flex;
  width: 290px;
}
.pagination a {
  flex: 1;
  text-decoration: none;
  color: #2c3e50;
}

#page-prev {
  text-align: left;
}

#page-next {
  text-align: right;
}
</style>
